import { isAuth } from "@/lib/api";
import { AppRoutes, LoginRoutes } from "@/routes/config";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const withNonAuth: <CP extends {}>(WrappedComponent: FC<CP>) => FC<CP> = (WrappedComponent) => (props) => {
  const { pathname } = useLocation();
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // verify auth routes
    if (isAuth() && LoginRoutes.some((path) => pathname.startsWith(path))) {
      navigate(AppRoutes.company);
    }

    setIsVerified(true);
  }, [pathname, navigate]);

  if (!isVerified) return null;

  return <WrappedComponent {...props} />;
};
