import { Box, BoxProps } from "@mui/material";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export type TLinkableProps = BoxProps & {
  href: string;
  target?: React.HTMLAttributeAnchorTarget;
  disabled?: boolean;
};

export const Linkable: FC<TLinkableProps> = ({ href, target, children, disabled = false, ...rest }) => {
  return (
    <Box position="relative" {...rest}>
      <StyledLink to={href} target={target} disabled={disabled} />
      {children}
    </Box>
  );
};

const StyledLink = styled(Link)<{ disabled: boolean }>(({ disabled }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 1,
  pointerEvents: disabled ? "none" : "unset",
}));
