import { IconChevronRight } from "@/assets/icons";
import { Linkable } from "@/components/elements/Linkable";
import { Table } from "@/components/elements/Table";
import { TCompany } from "@/entities/company.entity";
import { useCompanyList } from "@/features/Company/services/fetchCompanyListApi";
import { specificIndustryOptions } from "@/features/CompanyCreateOrUpdate/constants/company";
import { useTable } from "@/hooks/useTable";
import { findLabelByValue } from "@/lib/object";
import { getDynamicRoute } from "@/lib/url";
import { AppRoutes } from "@/routes/config";
import { useEnum } from "@/services/fetchEnumApi";
import { IconButton } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

export const CompanyTable = () => {
  const { data: companyContractStatus } = useEnum("ECompanyContractStatus");
  const columns = useMemo<MRT_ColumnDef<TCompany>[]>(
    () => [
      {
        header: "企業名",
        id: "companyName",
        accessorKey: "companyName",
        size: 120,
      },
      {
        header: "企業ID",
        id: "id",
        accessorKey: "id",
        size: 120,
      },
      {
        header: "当月1日時点での課金人数",
        size: 120,
        Cell: () => "Empty",
      },
      {
        header: "現在の課金人数",
        Cell: () => "Empty",
        size: 120,
      },
      {
        header: "分野",
        Cell: ({ row }) => findLabelByValue(specificIndustryOptions, row.original.specificIndustry) ?? "Empty",
      },
      {
        header: "ステータス",
        Cell: ({ row }) => findLabelByValue(companyContractStatus, row.original.contractStatus) ?? "Empty",
        size: 120,
      },
      {
        header: "契約開始日",
        Cell: () => "Empty",
        size: 120,
      },
      {
        header: "管理者ユーザー",
        Cell: () => "Empty",
        size: 120,
      },
      {
        header: "詳細",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Linkable href={getDynamicRoute(AppRoutes.companyDetail, { id })}>
              <IconButton>
                <IconChevronRight />
              </IconButton>
            </Linkable>
          );
        },
        size: 120,
      },
    ],
    [companyContractStatus],
  );

  const { data } = useCompanyList();
  const { table } = useTable({ columns, rows: data?.data ?? [] });

  return <Table table={table} />;
};
