import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { TAdminUser } from "@/entities/adminUser.entity";
import { schema } from "@/features/AdminUserCreateOrUpdate/schema/createForm";
import { createAdminUserApi } from "@/features/AdminUserCreateOrUpdate/services/createAdminUserApi";
import { TCreateAdminUserReqDto } from "@/features/AdminUserCreateOrUpdate/types/createAdminUser.dto";
import { useToast } from "@/hooks/useToast";
import { isApiError } from "@/lib/api";
import { useEnum } from "@/services/fetchEnumApi";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack } from "@mui/material";
import { FC } from "react";
import { useForm } from "react-hook-form";

const initCreateForm: TCreateAdminUserReqDto = {
  fullName: "",
  email: "",
  adminRole: "",
};

type TAdminUserFormProps = {
  previousData?: TAdminUser;
};

export const AdminUserForm: FC<TAdminUserFormProps> = ({ previousData }) => {
  const { data: adminRole } = useEnum("EAdminRole");
  const toast = useToast();
  const methods = useForm<TCreateAdminUserReqDto>({
    defaultValues: previousData ?? initCreateForm,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const { control } = methods;

  const onSubmit = async (data: TCreateAdminUserReqDto) => {
    try {
      const rs = await createAdminUserApi(data);
      methods.reset(initCreateForm);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
    } catch (error) {
      if (isApiError(error)) {
        toast.error(error.message);
      }
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Stack gap={3}>
            <FormField control={control} name="fullName" label="氏名">
              <Input placeholder="プレースホルダー" />
            </FormField>
            <FormField control={control} name="adminRole" label="担当">
              <Select options={adminRole} placeholder="プレースホルダー" />
            </FormField>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap={3}>
            <FormField control={control} name="email" label="メールアドレス">
              <Input placeholder="プレースホルダー" />
            </FormField>
          </Stack>
        </Grid>
      </Grid>
      <Box textAlign="center" mt={5}>
        <Button type="submit">メールを送信</Button>
      </Box>
    </Form>
  );
};
