import { InnerBox } from "@/components/elements/InnerBox";
import { TopPage } from "@/components/elements/TopPage";
import { AdminUserForm } from "@/features/AdminUserCreateOrUpdate/components/AdminUserForm";
import { Stack } from "@mui/material";
import { FC } from "react";

type TAdminUserCreateOrUpdatePageProps = {};
export const AdminUserCreateOrUpdatePage: FC<TAdminUserCreateOrUpdatePageProps> = () => {
  return (
    <Stack gap={4}>
      <TopPage title="irohanaメンバー新規追加" />
      <InnerBox padding="96px 75px" width="100%" maxWidth={814} mx="auto">
        <AdminUserForm />
      </InnerBox>
    </Stack>
  );
};
