import { clientApi, isAuth, setAxiosAuth } from "@/lib/api";
import { AdminRoutes, AppRoutes } from "@/routes/config";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const withAuth: <CP extends {}>(WrappedComponent: FC<CP>) => FC<CP> = (WrappedComponent) => (props) => {
  const { pathname } = useLocation();
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // set axios auth at init
    if (isAuth() && !clientApi.defaults.headers.common.Authorization) {
      setAxiosAuth();
    }
  }, []);

  useEffect(() => {
    // verify admin routes
    if (!isAuth() && AdminRoutes.some((path) => pathname.startsWith(path))) {
      navigate(AppRoutes.login);
    }

    setIsVerified(true);
  }, [pathname, navigate]);

  if (!isVerified) return null;

  return <WrappedComponent {...props} />;
};
