import { QUERY_KEYS } from "@/constants/queryKeys";
import { TCompanyUserDto } from "@/features/CompanyDetail/type/companyUserDto";
import { clientApi } from "@/lib/api";
import { TApiPagRes } from "@/types/api";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const fetchCompanyUserListApi = async (companyId: string): Promise<TCompanyUserDto[]> => {
  try {
    const { data } = await clientApi.get<TApiPagRes<TCompanyUserDto>>(`/companies/${companyId}/users`);

    return data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useCompanyUserList = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_USER_LIST, id],
    queryFn: () => (id ? fetchCompanyUserListApi(id) : undefined),
    enabled: Boolean(id),
  });
};
