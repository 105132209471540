import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from "@/assets/icons";
import { divider, text } from "@/theme/colors";
import { elevation } from "@/theme/variables";
import { Pagination as MuiPagination, PaginationItem, Stack, Typography, styled } from "@mui/material";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";

type Props = {
  totalPage: number;
  count?: number;
};

export const Pagination: FC<Props> = ({ totalPage, count }) => {
  const { pathname, search } = useLocation();

  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1");

  return (
    <Stack direction="row" alignItems="center" gap={2.5}>
      <Stack direction="row" gap={2}>
        {typeof count === "number" && (
          <Typography variant="sub16Semi" color={text.primary}>
            検索結果 <b>{count}</b>件
          </Typography>
        )}
        <Typography variant="sub16Semi" color={text.primary}>
          <b>
            {page}/{totalPage}
          </b>
          ページ目
        </Typography>
      </Stack>
      <StyledPagination
        page={page}
        count={totalPage}
        variant="outlined"
        shape="rounded"
        showFirstButton
        showLastButton
        renderItem={(item) => {
          const searchParams = new URLSearchParams(search);
          searchParams.set("page", item.page?.toString() ?? "1");

          if (["page", "end-ellipsis", "start-ellipsis"].includes(item.type)) return null;

          return (
            <PaginationItem
              component={Link}
              to={{ pathname, search: searchParams.toString() }}
              slots={{ first: IconChevronsLeft, previous: IconChevronLeft, next: IconChevronRight, last: IconChevronsRight }}
              {...item}
            />
          );
        }}
      />
    </Stack>
  );
};

const StyledPagination = styled(MuiPagination)`
  .MuiPaginationItem-root {
    border-radius: 6px;
    margin: 0 4px;
    box-shadow: ${elevation.sm};
    border-color: ${divider.middle};
  }
`;
