export const primary = {
  light: "#0781A8",
  main: "#0781A8",
  dark: "#0781A8",
  contrastText: "#0781A8",
  50: "#000D12",
  100: "#001A23",
  200: "#01222D",
  300: "#035771",
  400: "#0781A8",
  500: "#48A0B2",
  600: "#7EC7D2",
  700: "#AFDFE4",
  800: "#DAF1F2",
  900: "#ECF9F9",
} as const;

// ======== Primitive Color

export const black = {
  10: "#181A1B",
  20: "#1D2022",
  30: "#2E3436",
  60: "#4D5255",
  70: "#5A6063",
  80: "#767B7E",
  90: "#ABB4B6",
  95: "#CACDCD",
  99: "#E6E7E8",
  100: "#F8F9F9",
} as const;

export const blue = {
  30_1: "#000D12",
  30_2: "#001A23",
  30_3: "#01222D",
  60: "#035771",
  70: "#0781A8",
  80: "#48A0B2",
  90: "#7EC7D2",
  95: "#AFDFE4",
  99: "#DAF1F2",
  100: "#ECF9F9",
} as const;

export const secondary = {
  30: "#033C4D",
  60: "#0087AE",
  70: "#32BEE6",
  80: "#55CFEB",
  90: "#70D9EB",
  95: "#B3F1F9",
  99: "#E0FAFC",
} as const;

export const orange = {
  30: "#3E1A00",
  60: "#783300",
  70: "#E15F00",
  80: "#EC7126",
  90: "#FCA577",
  95: "#FECFBD",
  99: "#FFE7E0",
  100: "#FFF5F2",
} as const;

export const green = {
  30: "#005600",
  60: "#198F19",
  70: "#32BE32",
  80: "#59C955",
  90: "#7ED577",
  95: "#C4EBBD",
  99: "#E4F6E0",
} as const;

export const red = {
  30: "#69031E",
  60: "#BD1541",
  70: "#EE3A6A",
  80: "#F15B89",
  90: "#F47DA5",
  95: "#FFB9D4",
  99: "#FDE1EE",
} as const;

export const lightBlue = {
  30: "#033C4D",
  60: "#0087AE",
  70: "#32BEE6",
  80: "#55CFEB",
  90: "#70D9EB",
  95: "#B3F1F9",
  99: "#E0FAFC",
} as const;

export const pink = {
  30: "#69031E",
  60: "#BD1541",
  70: "#EE3A6A",
  80: "#F15B89",
  90: "#F47DA5",
  95: "#FFB9D4",
  99: "#FDE1EE",
} as const;

// ======== Semantics Color

export const background = {
  primary: "#F8F9F9",
  secondary: "#ECF9F9",
  main: "#0781A8",
  white: "#FFFFFF",
  orange: "#FFF5F2",
  error: "#FCDFE4",
  disable: "#E6E7E8",
  modal: "#090C12",
  dark: "#3E3E3E",
} as const;

export const text = {
  primary: "#0B0C0D",
  secondary: "#5A6063",
  tertiary: "#ABB4B6",
  disable: "#CACDCD",
  white: "#FFFFFF",
  error: "#E73030",
  accent: "#E15F00",
} as const;

export const button = {
  primary: "#48A0B2",
  hover_01: "#AFDFE4",
  hover_02: "#DAF1F2",
  disable: "#ABB4B6",
  pressed: "#035771",
  secondary: "#EC7126",
  danger: "#F1858D",
} as const;

export const state = {
  success_1: "#32BE32",
  success_2: "#E4F6E0",
  warning_1: "#EC7126",
  warning_2: "#FFE7E0",
  error_1: "#E73030",
  error_2: "#F3B6BD",
  link_1: "#185DE3",
  link_2: "#8DBBF2",
} as const;

export const divider = {
  high: "#CACDCD",
  middle: "#E6E7E8",
  low: "#F8F9F9",
  lower: "#FFFFFF",
} as const;

export const icon = {
  muted: "#7A8784",
};
