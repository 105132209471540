/**
 * Adds zero padding to a number.
 *
 * @param {number} num - The number to be padded. For example, 5.
 * @param {number} length - The desired length of the output string. For example, 3.
 * @returns {string} - The zero-padded number as a string. For example, "005".
 *
 */
export const zeroPad = (num: number | string, length: number) => {
  const numStr = num.toString();
  const zerosNeeded = length - numStr.length;
  const zeros = "0".repeat(zerosNeeded);

  return zeros + numStr;
};
