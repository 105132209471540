import { TCompany } from "@/entities/company.entity";
import { TCompanyUserForm } from "@/features/CompanyUserCreateOrEdit/types/companyUserForm";
import { clientApi } from "@/lib/api";
import { TApiRes } from "@/types/api";

export const updateCompanyUserApi = async (companyId: string, userId: number, payload: TCompanyUserForm): Promise<TApiRes<TCompany>> => {
  try {
    const { data } = await clientApi.put<TApiRes<TCompany>>(`/companies/${companyId}/users/${userId}`, payload);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
