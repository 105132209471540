import { Toast } from "@/components/elements/Toast";
import theme from "@/theme";
import { FCC } from "@/types/common";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      staleTime: 300000, // After 5 minute have elapsed since the data was retrieved
      gcTime: 300000, //  Data in cache is retained until 5 minute has passed
      retry: 0,
    },
  },
});

export const AppProvider: FCC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toast />
        {children}
      </ThemeProvider>
    </QueryClientProvider>
  );
};
