import { IconClose } from "@/assets/icons";
import { useFormField } from "@/components/elements/FormField/FormField";
import { background, blue, divider, lightBlue, state, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded } from "@/theme/variables";
import { IconButton, InputAdornment, TextField, TextFieldProps, css, styled } from "@mui/material";
import { ChangeEvent, FC, ReactNode, useMemo, useRef } from "react";

export type TInputSize = "md" | "lg";

export type TInputProps = {
  size?: TInputSize;
  readOnly?: boolean;
  previousValue?: string | number;
  clearable?: boolean;
  nonControl?: boolean;
  endAdornment?: ReactNode;
} & Omit<TextFieldProps, "size">;

export const Input: FC<TInputProps> = ({
  size = "md",
  readOnly = false,
  previousValue,
  clearable = true,
  nonControl = false,
  endAdornment,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>();
  const { field, fieldState } = useFormField(nonControl);

  const handleClear = () => {
    if (!ref.current) return;
    ref.current.value = "";
    field?.onChange("");
    field?.onBlur();

    rest.onChange?.({ target: ref.current } as ChangeEvent<HTMLInputElement>);
  };

  const inputEndAdornment = useMemo(() => {
    if (field?.value || rest.value) {
      if (!clearable || readOnly || rest.disabled) return endAdornment;
      return (
        <InputAdornment position="end">
          <IconButton onClick={handleClear} sx={{ p: 0, color: text.primary }}>
            <IconClose fontSize="20px" />
          </IconButton>
        </InputAdornment>
      );
    }
    return endAdornment;
  }, [field?.value, rest.value, clearable, endAdornment, readOnly, rest.disabled]);

  const isPrevious = useMemo(() => {
    if (fieldState?.isDirty === false) return true;
    return Boolean(previousValue) && (field?.value ?? rest.value) == previousValue;
  }, [fieldState?.isDirty, previousValue, field?.value, rest.value]);

  return (
    <StyledInput
      error={Boolean(fieldState?.error)}
      inputSize={size}
      inputProps={{ ref }}
      readOnly={readOnly}
      previous={isPrevious}
      InputProps={{
        endAdornment: inputEndAdornment,
      }}
      {...rest}
      {...field}
    />
  );
};

const options = { shouldForwardProp: (propName: string) => !["inputSize", "previous", "readOnly"].includes(propName) };

const StyledInput = styled(TextField, options)<{ inputSize: TInputSize; readOnly: boolean; previous: boolean }>`
  .MuiInputBase-root {
    input {
      color: ${text.primary};
      caret-color: ${blue[70]};
      &::placeholder {
        opacity: 1;
        color: ${text.tertiary}!important;
      }
    }
    fieldset {
      border-color: ${divider.middle};
      border-radius: ${rounded.xs};
      border-width: 1px !important;
    }
    &:hover,
    &.Mui-focused {
      fieldset {
        border-color: ${blue[70]};
      }
    }
    &.Mui-error {
      background: ${background.error};
      fieldset {
        border-color: ${state.error_1}!important;
      }
    }
    &.Mui-disabled {
      background: ${background.disable};
      fieldset {
        border-color: ${divider.middle}!important;
      }
    }
    ${({ inputSize }) => {
      if (inputSize === "md") return MediumInput;
      if (inputSize === "lg") return LargeInput;
      return MediumInput;
    }};
    ${({ readOnly }) => readOnly && ReadOnlyInput};
    ${({ previous }) => previous && PreviousInput};
  }
`;

const ReadOnlyInput = css`
  background: ${background.disable};
  pointer-events: none;
  fieldset {
    border-color: ${divider.middle}!important;
  }
`;

const PreviousInput = css`
  input {
    color: ${lightBlue[60]};
  }
`;

const MediumInput = css`
  .MuiInputBase-input {
    padding: 6px 8px;
    ${css(typography.body14)};
  }
`;

const LargeInput = css`
  .MuiInputBase-input {
    padding: 12px 8px;
    ${css(typography.body14)};
  }
`;
