import { Button } from "@/components/elements/Button";
import { Tag } from "@/components/elements/Tag";
import { TopPage } from "@/components/elements/TopPage";
import { CompanyPaymentTable } from "@/features/CompanyDetail/components/CompanyPaymentTable";
import { CompanySummary } from "@/features/CompanyDetail/components/CompanySummary";
import { CompanyUserTable } from "@/features/CompanyDetail/components/CompanyUserTable";
import { useCompany } from "@/features/CompanyDetail/services/fetchCompanyApi";
import { zeroPad } from "@/lib/number";
import { getDynamicRoute } from "@/lib/url";
import { AppRoutes } from "@/routes/config";
import { state } from "@/theme/colors";
import { Stack } from "@mui/material";
import { FC } from "react";

export const CompanyDetailPage: FC = () => {
  const { data, isLoading } = useCompany();

  if (isLoading || !data) return null;

  return (
    <Stack gap={3}>
      <TopPage
        title={data.companyName}
        subTitle={zeroPad(data.id ?? "", 3)}
        backUrl={AppRoutes.company}
        rightContent={
          <Stack direction="row" alignItems="center" gap={3}>
            <Tag color={state.success_1} title="契約中" variant="light" />
            <Button variant="primary" href={getDynamicRoute(AppRoutes.companyEdit, { id: data.id })}>
              編集
            </Button>
          </Stack>
        }
      />
      <CompanySummary />
      <CompanyUserTable />
      <CompanyPaymentTable />
    </Stack>
  );
};
