import React, { SVGProps } from "react";

export const IconChevronsRight = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M5 14.1666L9.16667 9.99992L5 5.83325M10.8333 14.1666L15 9.99992L10.8333 5.83325"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};
