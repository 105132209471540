import { InnerBox } from "@/components/elements/InnerBox";
import { TopPage } from "@/components/elements/TopPage";
import { CompanyForm } from "@/features/CompanyCreateOrUpdate/components/CompnayForm";
import { useCompany } from "@/features/CompanyDetail/services/fetchCompanyApi";
import { getDynamicRoute } from "@/lib/url";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import React, { FC } from "react";
import { useParams } from "react-router-dom";

type TCompanyCreateOrUpdatePageProps = {};

export const CompanyCreateOrUpdatePage: FC<TCompanyCreateOrUpdatePageProps> = () => {
  const { id } = useParams();
  const { data, isLoading } = useCompany();

  if (isLoading) return null;

  return (
    <Stack gap={4}>
      <TopPage
        title="受け入れ企業を追加"
        description="企業の基本情報を入力して追加しましょう"
        backUrl={getDynamicRoute(AppRoutes.companyDetail, { id })}
      />
      <InnerBox width="100%" maxWidth={814} mx="auto">
        <CompanyForm previousData={data} />
      </InnerBox>
    </Stack>
  );
};
