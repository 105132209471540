import { TCompany } from "@/entities/company.entity";
import { TCompanyForm } from "@/features/CompanyCreateOrUpdate/types/companyForm";
import { clientApi } from "@/lib/api";
import { TApiRes } from "@/types/api";

export const createCompanyApi = async (payload: TCompanyForm): Promise<TApiRes<TCompany>> => {
  try {
    const { data } = await clientApi.post<TApiRes<TCompany>>("/companies", payload);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
