import { Box, BoxProps } from "@mui/material";
import { forwardRef } from "react";
import styled from "styled-components";

export type TClickableProps = BoxProps & {
  onClick: () => void;
  disabled?: boolean;
};

export const Clickable = forwardRef<HTMLDivElement, TClickableProps>(({ onClick, children, disabled = false, ...rest }, ref) => {
  return (
    <ClickableWrap {...rest} ref={ref}>
      <StyledButton disabled={disabled} onClick={onClick} />
      {children}
    </ClickableWrap>
  );
});

const StyledButton = styled("button")<{ disabled: boolean }>(({ disabled }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 1,
  pointerEvents: disabled ? "none" : "unset",
  opacity: 0,
  cursor: "pointer",
}));

const ClickableWrap = styled(Box)`
  position: relative;
  width: fit-content;
`;
