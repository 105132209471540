import { QUERY_KEYS } from "@/constants/queryKeys";
import { TAdminUser } from "@/entities/adminUser.entity";
import { usePagination } from "@/hooks/usePagination";
import { clientApi } from "@/lib/api";
import { createUrlWithParams } from "@/lib/url";
import { TApiPagReq, TApiPagRes } from "@/types/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export const fetchAdminUserListApi = async (req: TApiPagReq): Promise<TApiPagRes<TAdminUser>> => {
  try {
    const url = createUrlWithParams("/users", req);
    const { data } = await clientApi.get<TApiPagRes<TAdminUser>>(url);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useAdminUserList = () => {
  const pagReq = usePagination();

  return useQuery({
    queryKey: [QUERY_KEYS.ADMIN_USER.FETCH_LIST, pagReq],
    queryFn: () => fetchAdminUserListApi(pagReq),
    placeholderData: keepPreviousData,
  });
};
