import { USER_TYPE } from "@/types/enum";
import { object, string } from "yup";

export const schema = object().shape({
  fullName: string().required(),
  email: string().email().required(),
  type: string().required(),
  companyDepartment: string().when("type", ([type], schema) => {
    if (type === USER_TYPE.COMPANY_USER) return schema.required();
    return schema.optional();
  }),
});
