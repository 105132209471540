import { Box, BoxProps } from "@mui/material";
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form";

type TFormProps<T extends FieldValues> = {
  methods: UseFormReturn<T, object, undefined>;
  onSubmit: (data: T) => void;
} & Omit<BoxProps, "onSubmit">;

export const Form = <T extends FieldValues>({ methods, onSubmit, ...rest }: TFormProps<T>) => {
  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} {...rest} />
    </FormProvider>
  );
};
