import { removeAxiosAuth, setAxiosAuth } from "@/lib/api";
import Cookies from "js-cookie";

export const setToken = (accessToken: string, refreshToken: string) => {
  Cookies.set("token", accessToken);
  Cookies.set("refreshToken", refreshToken);
  setAxiosAuth();
};

export const removeToken = () => {
  Cookies.remove("token");
  Cookies.remove("refreshToken");
  removeAxiosAuth();
};
