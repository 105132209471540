import { IconExpandMore } from "@/assets/icons";
import { useFormField } from "@/components/elements/FormField/FormField";
import { background, blue, button, divider, lightBlue, state, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { rounded } from "@/theme/variables";
import { TOption } from "@/types/common";
import { Box, FormHelperText, MenuItem, Select as MuiSelect, SelectProps, Typography, css, styled } from "@mui/material";
import { FC, useMemo } from "react";

export type TSelectSize = "md" | "lg";
export type TSelectOption = TOption & {
  disabled?: boolean;
};

type TInputDateProps = {
  size?: TSelectSize;
  readOnly?: boolean;
  previousValue?: string | number;
  helperText?: string;
  nonControl?: boolean;
  options: TSelectOption[];
} & Omit<SelectProps, "size" | "multiple" | "children">;

export const Select: FC<TInputDateProps> = ({
  size = "md",
  readOnly = false,
  previousValue = false,
  nonControl = false,
  placeholder,
  helperText,
  options,
  ...rest
}) => {
  const { field, fieldState } = useFormField(nonControl);

  const isPrevious = useMemo(() => {
    if (fieldState?.isDirty === false) return true;
    return Boolean(previousValue) && (field?.value ?? rest.value) == previousValue;
  }, [fieldState?.isDirty, previousValue, field?.value, rest.value]);

  return (
    <Box>
      <StyledSelect
        error={Boolean(fieldState?.error)}
        selectSize={size}
        readOnly={readOnly}
        previous={isPrevious}
        displayEmpty
        IconComponent={IconExpandMore}
        {...rest}
        {...field}
      >
        {Boolean(placeholder) && (
          <PlaceHolderItem value="" disabled>
            <Typography variant="body14" color={`${text.tertiary}!important`}>
              {placeholder}
            </Typography>
          </PlaceHolderItem>
        )}
        {options.map(({ value, label, disabled }) => (
          <MenuItem key={value} value={value} disabled={disabled}>
            {label}
          </MenuItem>
        ))}
      </StyledSelect>
      {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  );
};

const options = { shouldForwardProp: (propName: string) => !["selectSize", "previous", "readOnly"].includes(propName) };

const StyledSelect = styled(MuiSelect, options)<{ selectSize: TSelectSize; readOnly: boolean; previous: boolean }>`
  width: 100%;
  &.MuiInputBase-root {
    position: relative;
    .MuiSelect-select {
      color: ${text.primary};
    }
    &::placeholder {
      color: ${text.tertiary};
    }
    fieldset {
      border-color: ${divider.middle};
      border-radius: ${rounded.xs};
      border-width: 1px !important;
    }
    &:hover,
    &.Mui-focused {
      fieldset {
        border-color: ${blue[70]};
      }
    }
    &.Mui-focused {
      &::after {
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        width: 100%;
        height: 100%;
        padding: 2px;
        border: 2px solid ${button.secondary};
        box-sizing: content-box;
        border-radius: ${rounded.sm};
        z-index: -1;
      }
    }

    &.Mui-error {
      background: ${background.error};
      fieldset {
        border-color: ${state.error_1}!important;
      }
    }
    &.Mui-disabled {
      background: ${background.disable};
      fieldset {
        border-color: ${divider.middle}!important;
      }
    }
    .MuiSelect-icon {
      transform: none !important;
      color: ${text.primary};
    }

    ${({ selectSize }) => {
      if (selectSize === "md") return MediumInput;
      if (selectSize === "lg") return LargeInput;
      return MediumInput;
    }};
    ${({ readOnly }) => readOnly && ReadOnlyInput};
    ${({ previous }) => previous && PreviousInput};
  }
`;

const ReadOnlyInput = css`
  background: ${background.disable};
  pointer-events: none;
  fieldset {
    border-color: ${divider.middle}!important;
  }
`;

const PreviousInput = css`
  .MuiSelect-select {
    color: ${lightBlue[60]};
  }
`;

const MediumInput = css`
  .MuiInputBase-input {
    padding: 6px 8px;
    ${css(typography.body14)};
  }
`;

const LargeInput = css`
  .MuiInputBase-input {
    padding: 12px 8px;
    ${css(typography.body14)};
  }
`;

const PlaceHolderItem = styled(MenuItem)`
  display: none;
  color: ${text.tertiary}!important;
`;
