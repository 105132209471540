import { TCompany } from "@/entities/company.entity";
import { clientApi } from "@/lib/api";
import { TApiRes } from "@/types/api";

export const deleteCompanyUserApi = async (companyId: number, userId: number): Promise<TApiRes<TCompany>> => {
  try {
    const { data } = await clientApi.delete<TApiRes<TCompany>>(`/companies/${companyId}/users/${userId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
