import { verifyToken } from "@/lib/verifyToken";
import { refreshTokenApi } from "@/services/refreshTokenApi";
import { TApiError } from "@/types/api";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";

if (!process.env.REACT_APP_API_HOST) {
  throw new Error("please provide REACT_APP_API_HOST in env");
}

export const baseURL = `${process.env.REACT_APP_API_HOST}/admin`;

export const globalBaseURL = `${process.env.REACT_APP_API_HOST}/global`;

export const globalApi = axios.create({
  baseURL: globalBaseURL,
});

export const clientApi = axios.create({
  baseURL,
});

// If successful (do nothing)
export const onSuccess = (response: AxiosResponse) => response;

let isRefreshing = false;
let refreshQueue: ((config: AxiosRequestConfig) => void)[] = [];
//  in case of error
export const onError = async (error: AxiosError) => {
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401 && error.config) {
      Cookies.remove("token");
      if (!isRefreshing) {
        const errorHeader = !error.config.headers.Authorization && Cookies.get("token");

        if (!errorHeader) {
          isRefreshing = true;
          // Call an API to refresh the token here and store the new token
          await refreshTokenApi();
          isRefreshing = false;
        }

        const newToken = Cookies.get("token");

        // Retry all the requests in the queue
        refreshQueue.forEach((request) =>
          request({
            headers: {
              Authorization: `Bearer ${newToken}`,
            },
          }),
        );
        refreshQueue = [];
        return axios({
          ...error.config,
          headers: {
            Authorization: `Bearer ${newToken}`,
          },
        });
      }

      // Return a promise to wait for the request to be retried after the token is updated
      return new Promise((resolve) => {
        refreshQueue.push((config: AxiosRequestConfig) => {
          resolve(axios(config));
        });
      });
    }
  }

  return Promise.reject(error.response?.data);
};

clientApi.interceptors.response.use(onSuccess, onError);

export const setAxiosAuth = () => {
  const token = Cookies.get("token");
  clientApi.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeAxiosAuth = () => {
  clientApi.defaults.headers.common.Authorization = "";
};

export const isAuth = () => {
  if (typeof window !== "undefined") {
    const token = Cookies.get("token");
    const isAuthenticated = !!token && verifyToken(token);
    return isAuthenticated;
  }
  return false;
};

export const isApiError = (error: unknown): error is TApiError => {
  if (error && typeof error === "object" && "message" in error) return true;
  return false;
};
