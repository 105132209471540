import { TopPage } from "@/components/elements/TopPage";
import { CompanyListTop } from "@/features/Company/components/CompanyListTop";
import { CompanyTable } from "@/features/Company/components/CompanyTable";
import { Box, Stack } from "@mui/material";
import { FC } from "react";

type TCompanyPageProps = {};

export const CompanyPage: FC<TCompanyPageProps> = () => {
  return (
    <Box>
      <TopPage title="受け入れ企業の一覧" enablePageBack={false} />
      <Stack gap={3}>
        <CompanyListTop />
        <CompanyTable />
      </Stack>
    </Box>
  );
};
