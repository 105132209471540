import { Filter } from "@/components/elements/Filter";
import { Switch } from "@/components/elements/Switch";
import { Table } from "@/components/elements/Table";
import { useTable } from "@/hooks/useTable";
import { Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import React, { useMemo } from "react";

export const CompanyPaymentTable = () => {
  const columns = useMemo<MRT_ColumnDef<{}>[]>(
    () => [
      {
        header: "外国人名",
        size: 120,
      },
      {
        header: "国籍",
        Header: <Filter options={[]}>国籍</Filter>,
        size: 120,
      },
      {
        header: "入社日",
        Header: <Filter options={[]}>入社日</Filter>,
        size: 120,
      },
      {
        header: "前月1日時点での課金形態",
        Header: <Filter options={[]}>前月1日時点での課金形態</Filter>,
        size: 120,
      },
      {
        header: "現時点での課金形態",
        Header: <Filter options={[]}>現時点での課金形態</Filter>,
        size: 120,
      },
      {
        header: "課金あり",
        Header: <Filter options={[]}>課金あり</Filter>,
        Cell: () => {
          return <Switch />;
        },
        size: 120,
      },
    ],
    [],
  );

  const { table } = useTable({ columns, rows: [{}, {}] });

  return (
    <Stack gap={1}>
      <Typography variant="sub16Bold">課金状況</Typography>
      <Table table={table} />
    </Stack>
  );
};
