import { QUERY_KEYS } from "@/constants/queryKeys";
import { TCompany } from "@/entities/company.entity";
import { clientApi } from "@/lib/api";
import { TApiRes } from "@/types/api";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const fetchCompanyApi = async (companyId: string): Promise<TCompany> => {
  try {
    const { data } = await clientApi.get<TApiRes<TCompany>>(`/companies/${companyId}`);
    return data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useCompany = () => {
  const { id } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_DETAIL, id],
    queryFn: () => (id ? fetchCompanyApi(id) : undefined),
    enabled: Boolean(id),
  });
};
