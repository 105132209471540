import { Button } from "@/components/elements/Button";
import { InnerBox } from "@/components/elements/InnerBox";
import { TopPage } from "@/components/elements/TopPage";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { CompanyUserForm } from "@/features/CompanyUserCreateOrEdit/components/CompanyUserForm";
import { deleteCompanyUserApi } from "@/features/CompanyUserCreateOrEdit/services/deleteCompanyUserApi";
import { useCompanyUser } from "@/features/CompanyUserCreateOrEdit/services/fetchCompanyUserApi";
import { useToast } from "@/hooks/useToast";
import { isApiError } from "@/lib/api";
import { getDynamicRoute } from "@/lib/url";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

type TCompanyUserCreateOrEditPageProps = {};

export const CompanyUserCreateOrEditPage: FC<TCompanyUserCreateOrEditPageProps> = () => {
  const { id } = useParams();
  const { data, isLoading } = useCompanyUser();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      if (!data?.id) throw new Error();
      if (!data?.companyId) throw new Error();
      const rs = await deleteCompanyUserApi(data.companyId, data.id);
      toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      navigate(getDynamicRoute(AppRoutes.companyDetail, { id: data.companyId }));

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_USER_LIST] });
    } catch (error) {
      if (isApiError(error)) {
        toast.error(error.message);
      }
    }
  };

  if (isLoading) return null;

  return (
    <Stack gap={4}>
      {Boolean(data) && (
        <TopPage
          title="企業ユーザーの編集"
          backUrl={getDynamicRoute(AppRoutes.companyDetail, { id })}
          rightContent={
            <Button variant="outline" size="lg" onClick={handleDelete}>
              削除
            </Button>
          }
        />
      )}
      {!Boolean(data) && (
        <TopPage
          backUrl={getDynamicRoute(AppRoutes.companyDetail, { id })}
          title="企業ユーザーの追加"
          description="企業ユーザーに招待メールを送り、メールのURLから登録してもらう"
        />
      )}
      <InnerBox width="100%" maxWidth={814} mx="auto">
        <CompanyUserForm previousData={data} />
      </InnerBox>
    </Stack>
  );
};
