import React, { SVGProps } from "react";

export const IconChevronsLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.16667 14.1666L5 9.99992L9.16667 5.83325M15 14.1666L10.8333 9.99992L15 5.83325"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};
