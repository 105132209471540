import { background, divider, text } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { FCC } from "@/types/common";
import { Box, Stack, StackProps, Typography, styled } from "@mui/material";
import { ReactNode, useMemo } from "react";

export type TInnerBoxProps = {
  title?: ReactNode | string;
  bordered?: boolean;
  required?: boolean;
} & StackProps;

export const InnerBox: FCC<TInnerBoxProps> = ({ title, children, bordered = true, padding = "16px", required = false, ...rest }) => {
  const renderTitle = useMemo(() => {
    if (!title) return null;
    if (typeof title === "string") {
      return (
        <Typography variant="head20Bold" color={text.primary}>
          {title}
        </Typography>
      );
    }
    return title;
  }, [title]);

  return (
    <InnerBoxWrap border={bordered ? "1px solid" : "none"} padding={padding} {...rest}>
      {(renderTitle || required) && (
        <Stack direction="row" gap={1} alignItems="center">
          {renderTitle}
          {required && <RequiredTag variant="cap12">必須</RequiredTag>}
        </Stack>
      )}
      <Box>{children}</Box>
    </InnerBoxWrap>
  );
};

const InnerBoxWrap = styled(Stack)`
  border-color: ${divider.middle};
  border-radius: ${rounded.sm};
  gap: ${spacing.sm};
  background: ${background.white};
`;

const RequiredTag = styled(Typography)`
  height: 20px;
  width: 40px;
  text-align: center;
  border: 1px solid ${text.error};
  color: ${text.error};
  border-radius: ${rounded.xs};
`;
