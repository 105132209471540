import { PageBack } from "@/components/elements/PageBack";
import { Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

export type TTopPageProps = {
  title: string;
  subTitle?: string;
  description?: string;
  backUrl?: string;
  rightContent?: ReactNode;
  onBack?: () => void;
  enablePageBack?: boolean;
};

export const TopPage: FC<TTopPageProps> = ({ title, subTitle, description, backUrl, enablePageBack = true, rightContent, onBack }) => {
  return (
    <Stack gap={3} pb={3}>
      {enablePageBack && <PageBack backUrl={backUrl} onBack={onBack} />}
      <Stack direction="row" gap={3} alignItems="center">
        <Stack gap={1} pt={enablePageBack ? 3 : 0} flex={1}>
          <Stack gap={0.5}>
            {subTitle && <Typography variant="body14">{subTitle}</Typography>}
            <Typography variant="head24Bold">{title}</Typography>
          </Stack>
          {description && <Typography variant="body14">{description}</Typography>}
        </Stack>
        {rightContent}
      </Stack>
    </Stack>
  );
};
