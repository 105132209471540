import { DEFAULT_PAGINATION_REQUEST, DEFAULT_PER_PAGE, START_PAGE } from "@/constants/pagination";
import { TApiPagReq } from "@/types/api";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const usePagination = (): TApiPagReq => {
  const { pathname, search } = useLocation();

  const pagination = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const data = { ...DEFAULT_PAGINATION_REQUEST };

    data.page = Number(query.get("page") || START_PAGE);
    data.perPage = Number(query.get("perPage") || DEFAULT_PER_PAGE);

    return data;
  }, [search, pathname]);

  return pagination;
};
