import { IconAlert } from "@/assets/icons";
import { text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { FormControl, FormControlProps, FormHelperText, FormLabel, css, styled } from "@mui/material";
import { createContext, useContext } from "react";
import { Control, Controller, ControllerFieldState, ControllerRenderProps, FieldValues, Path, UseFormStateReturn } from "react-hook-form";

type TFormFieldType = {
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<FieldValues>;
};

export const FormFieldContext = createContext<Partial<TFormFieldType>>({});
export const useFormField = (nonControl = false) => (nonControl ? {} : useContext(FormFieldContext));

type FormFieldProps<T extends FieldValues> = {
  name?: Path<T>;
  control?: Control<T>;
  label?: string;
  helper?: string;
} & FormControlProps;

export const FormField = <T extends FieldValues>({ name, control, label, children, helper, ...rest }: FormFieldProps<T>) => {
  if (!control || !name) {
    return (
      <StyledFormControl {...rest}>
        {label && (
          <FormLabel>
            {label} {helper && <IconAlert />}
          </FormLabel>
        )}
        {children}
      </StyledFormControl>
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={(controller) => {
        const { fieldState } = controller;
        return (
          <FormFieldContext.Provider value={controller as unknown as TFormFieldType}>
            <StyledFormControl error={Boolean(fieldState.error)} {...rest}>
              {label && <FormLabel>{label}</FormLabel>}
              {children}
              {fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>}
            </StyledFormControl>
          </FormFieldContext.Provider>
        );
      }}
    />
  );
};

const StyledFormControl = styled(FormControl)`
  width: 100%;
  .MuiFormLabel-root,
  .MuiFormLabel-root.Mui-error {
    color: ${text.primary};
    margin-bottom: 4px;
    ${css(typography.cap12)};
    &.Mui-focused {
      color: ${text.primary};
    }
  }
  .MuiFormHelperText-root {
    margin-top: 4px;
    margin-left: 0;
    ${css(typography.cap12)};
  }
`;
