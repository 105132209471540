export const specificIndustryOptions = [
  { label: "介護分野", value: "介護分野" },
  { label: "ビルクリーニング分野（特定技能１号）", value: "ビルクリーニング分野（特定技能１号）" },
  { label: "ビルクリーニング分野（特定技能２号）", value: "ビルクリーニング分野（特定技能２号）" },
  { label: "素形材・産業機械・電気電子（特定技能１号）", value: "素形材・産業機械・電気電子（特定技能１号）" },
  { label: "素形材・産業機械・電気電子（特定技能２号）", value: "素形材・産業機械・電気電子（特定技能２号）" },
  { label: "建設分野（特定技能１号）", value: "建設分野（特定技能１号）" },
  { label: "建設分野（特定技能２号）", value: "建設分野（特定技能２号）" },
  { label: "造船・舶用工業分野（特定技能１号）", value: "造船・舶用工業分野（特定技能１号）" },
  { label: "造船・舶用工業分野（特定技能２号）", value: "造船・舶用工業分野（特定技能２号）" },
  { label: "自動車整備分野（特定技能１号）", value: "自動車整備分野（特定技能１号）" },
  { label: "自動車整備分野（特定技能２号）", value: "自動車整備分野（特定技能２号）" },
  { label: "航空分野（特定技能１号）", value: "航空分野（特定技能１号）" },
  { label: "航空分野（特定技能２号）", value: "航空分野（特定技能２号）" },
  { label: "宿泊分野（特定技能１号）", value: "宿泊分野（特定技能１号）" },
  { label: "宿泊分野（特定技能２号）", value: "宿泊分野（特定技能２号）" },
  { label: "農業分野（特定技能１号）", value: "農業分野（特定技能１号）" },
  { label: "農業分野（特定技能２号）", value: "農業分野（特定技能２号）" },
  { label: "漁業分野（特定技能１号）", value: "漁業分野（特定技能１号）" },
  { label: "漁業分野（特定技能２号）", value: "漁業分野（特定技能２号）" },
  { label: "飲食料品製造業分野（特定技能１号）", value: "飲食料品製造業分野（特定技能１号）" },
  { label: "飲食料品製造業分野（特定技能２号）", value: "飲食料品製造業分野（特定技能２号）" },
  { label: "外食業分野（特定技能１号）", value: "外食業分野（特定技能１号）" },
  { label: "外食業分野（特定技能２号）", value: "外食業分野（特定技能２号）" },
];
