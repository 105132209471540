import { withAuth } from "@/hoc/withAuth";
import { AppRoutes } from "@/routes/config";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.remove("token");
    navigate(AppRoutes.login);
  }, [navigate]);

  return null;
};

export default withAuth(Logout);
