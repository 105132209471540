import { QUERY_KEYS } from "@/constants/queryKeys";
import { TCompany } from "@/entities/company.entity";
import { usePagination } from "@/hooks/usePagination";
import { clientApi } from "@/lib/api";
import { createUrlWithParams } from "@/lib/url";
import { TApiPagReq, TApiPagRes } from "@/types/api";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export const fetchCompanyListApi = async (req: TApiPagReq): Promise<TApiPagRes<TCompany>> => {
  try {
    const url = createUrlWithParams("/companies", req);
    const { data } = await clientApi.get<TApiPagRes<TCompany>>(url);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useCompanyList = () => {
  const pagReq = usePagination();

  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_LIST, pagReq],
    queryFn: () => fetchCompanyListApi(pagReq),
    placeholderData: keepPreviousData,
  });
};
