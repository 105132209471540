import { jwtDecode } from "jwt-decode";

export function verifyToken(token: string) {
  try {
    const decoded = jwtDecode<{ exp: number }>(token);
    return decoded.exp > new Date().getTime() / 1000;
  } catch (e) {
    return false;
  }
}
