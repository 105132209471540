import { background, button, divider } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { Box, styled } from "@mui/material";
import { MRT_RowData, MRT_TableInstance, MaterialReactTable } from "material-react-table";

type Props<T extends MRT_RowData> = {
  table: MRT_TableInstance<T>;
};

export const Table = <T extends MRT_RowData>({ table }: Props<T>) => {
  return (
    <StyledTable>
      <MaterialReactTable table={table} />
    </StyledTable>
  );
};

const StyledTable = styled(Box)`
  border-radius: ${rounded.sm};
  overflow: hidden;
  border: 1px solid ${divider.middle};
  * {
    box-shadow: none;
  }
  .MuiToolbar-dense {
    display: none;
  }
  .MuiTableCell-head {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  .Mui-TableHeadCell-Content-Labels {
    > span {
      margin-left: 4px;
      img {
        opacity: 0.2;
      }
      .MuiBadge-badge {
        display: none;
      }
    }
  }
  .MuiTableCell-body {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }

  // Default
  .MuiTableRow-root {
    background-color: ${background.white};
    box-shadow: none;
    cursor: initial;
    .MuiTableCell-root {
      padding: ${spacing.xxs} ${spacing.sm};
      border-color: ${divider.middle};
      white-space: nowrap;
    }
    &:not(.MuiTableRow-head):last-child {
      .MuiTableCell-root {
        border: 0;
      }
    }
  }
  // Hover
  .MuiTableRow-root:not(.MuiTableRow-head):hover {
    &,
    td {
      background-color: ${background.white};
      &::after {
        background-color: ${background.primary};
      }
    }
  }
  // Click/Pressed
  .Mui-selected {
    background-color: ${background.white} !important;
    td::after {
      background: ${background.secondary}!important;
    }
  }

  .MuiPaper-root > .MuiBox-root {
    display: none;
  }

  .MuiCheckbox-root {
    color: ${button.primary}!important;
    height: 24px;
    width: 24px;
    padding: 0;
  }

  // Row disabled
  .MuiTableCell-root.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  // Row highlight
  .MuiTableCell-root.highlight {
    background: ${background.error}!important;
    &::after {
      background: unset !important;
    }
  }
`;
