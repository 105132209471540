import { AdminLayoutWithAuth } from "@/components/layout/AdminLayout/AdminLayout";
import { LoginLayoutWithNonAuth } from "@/components/layout/LoginLayout/LoginLayout";
import AdminUser from "@/pages/AdminUser";
import AdminUserCreate from "@/pages/AdminUserCreate";
import Company from "@/pages/Company/Company";
import CompanyCreate from "@/pages/CompanyCreate/CompanyCreate";
import CompanyDetail from "@/pages/CompanyDetail/CompanyDetail";
import CompanyEdit from "@/pages/CompanyEdit/CompanyEdit";
import CompanyUserCreate from "@/pages/CompanyUserCreate/CompanyUserCreate";
import CompanyUserEdit from "@/pages/CompanyUserEdit/CompanyUserEdit";
import Login from "@/pages/Login";
import Logout from "@/pages/Logout";
import PageNotFound from "@/pages/PageNotFound";
import { AppRoutes } from "@/routes/config";
import { RouteObject } from "react-router-dom";

export const routes: RouteObject[] = [
  {
    element: <LoginLayoutWithNonAuth />,
    children: [
      {
        path: AppRoutes.login,
        element: <Login />,
      },
      {
        path: AppRoutes.logout,
        element: <Logout />,
      },
    ],
  },
  {
    element: <AdminLayoutWithAuth />,
    children: [
      {
        path: AppRoutes.company,
        element: <Company />,
      },
      { path: AppRoutes.companyCreate, element: <CompanyCreate /> },
      { path: AppRoutes.companyDetail, element: <CompanyDetail /> },
      { path: AppRoutes.companyEdit, element: <CompanyEdit /> },
      { path: AppRoutes.companyUserCreate, element: <CompanyUserCreate /> },
      { path: AppRoutes.companyUserEdit, element: <CompanyUserEdit /> },
      { path: AppRoutes.adminUser, element: <AdminUser /> },
      { path: AppRoutes.adminUserCreate, element: <AdminUserCreate /> },
      { path: AppRoutes.adminUserDetail },
      { path: AppRoutes.adminUserEdit },
      { path: AppRoutes.notification },
      { path: AppRoutes.notificationCreate },
      { path: AppRoutes.notificationDetail },
      { path: AppRoutes.convert },
      { path: AppRoutes.convertHistory },
      { path: AppRoutes.convertDetail },
    ],
  },
  { path: "*", element: <PageNotFound /> },
];
