import { IconChevronRight } from "@/assets/icons";
import { Linkable } from "@/components/elements/Linkable";
import { Table } from "@/components/elements/Table";
import { TAdminUser } from "@/entities/adminUser.entity";
import { useAdminUserList } from "@/features/AdminUser/services/fetchAdminUserListApi";
import { useTable } from "@/hooks/useTable";
import { findLabelByValue } from "@/lib/object";
import { getDynamicRoute } from "@/lib/url";
import { AppRoutes } from "@/routes/config";
import { useEnum } from "@/services/fetchEnumApi";
import { IconButton } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";

export const AdminUserTable = () => {
  const { data: adminRole } = useEnum("EAdminRole");
  const columns = useMemo<MRT_ColumnDef<TAdminUser>[]>(
    () => [
      {
        header: "名前",
        Cell: ({ row }) => row.original.fullName ?? "Empty",
        size: 120,
      },
      {
        header: "メールアドレス",
        Cell: ({ row }) => row.original.email ?? "Empty",
        size: 120,
      },
      {
        header: "担当",
        Cell: ({ row }) => findLabelByValue(adminRole, row.original.adminRole) ?? "Empty",
        size: 120,
      },
      {
        header: "詳細へ",
        Cell: ({ row }) => {
          const { id } = row.original;
          return (
            <Linkable href={getDynamicRoute(AppRoutes.adminUserDetail, { id })}>
              <IconButton>
                <IconChevronRight />
              </IconButton>
            </Linkable>
          );
        },
        size: 120,
      },
    ],
    [adminRole],
  );

  const { data } = useAdminUserList();
  const { table } = useTable({ columns, rows: data?.data ?? [] });

  return <Table table={table} />;
};
