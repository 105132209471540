import { useFormField } from "@/components/elements/FormField";
import { button, text } from "@/theme/colors";
import { FormControlLabel, Stack, Switch as MuiSwitch, SwitchProps, Typography, styled } from "@mui/material";
import { FC, ReactNode } from "react";

export type TSwitchProps = {
  label?: string;
  subLabel?: string;
  nonControl?: boolean;
  prefixLabel?: ReactNode;
} & SwitchProps;

export const Switch: FC<TSwitchProps> = ({ label, subLabel, nonControl = false, prefixLabel, ...rest }) => {
  const { field } = useFormField(nonControl);

  return (
    <FormControlLabel
      label={
        <Stack flexDirection="row" gap={0.5}>
          {prefixLabel}
          <Stack gap={0.5}>
            {label && (
              <Typography variant="body14" color={text.primary}>
                {label}
              </Typography>
            )}
            {subLabel && (
              <Typography variant="cap12" color={text.secondary}>
                {subLabel}
              </Typography>
            )}
          </Stack>
        </Stack>
      }
      control={<IOSSwitch {...rest} {...field} />}
    />
  );
};

const IOSSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: button.primary,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: button.primary,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
