import { Button } from "@/components/elements/Button";
import { Table } from "@/components/elements/Table";
import { useCompanyUserList } from "@/features/CompanyDetail/services/fetchCompanyUserListApi";
import { TCompanyUserDto } from "@/features/CompanyDetail/type/companyUserDto";
import { affiliation } from "@/features/CompanyUserCreateOrEdit/constants/companyUser";
import { useTable } from "@/hooks/useTable";
import { findLabelByValue } from "@/lib/object";
import { getDynamicRoute } from "@/lib/url";
import { AppRoutes } from "@/routes/config";
import { USER_TYPE } from "@/types/enum";
import { Link, Stack, Typography } from "@mui/material";
import { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const CompanyUserTable = () => {
  const { id } = useParams();
  const columns = useMemo<MRT_ColumnDef<TCompanyUserDto>[]>(
    () => [
      {
        header: "ユーザー名",
        id: "fullName",
        accessorKey: "fullName",
        size: 120,
      },
      {
        header: "メールアドレス",
        id: "email",
        accessorKey: "email",
        size: 120,
      },
      {
        header: "所属",
        size: 120,
        Cell: ({ row }) => (row.original.type ? findLabelByValue(affiliation, row.original.type) : "Empty"),
      },
      {
        header: "部署",
        accessorKey: "companyDepartment",
        size: 120,
      },
      {
        header: "管理者",
        Cell: ({ row }) => {
          if (row.original.type === USER_TYPE.ADMIN_MEMBER) {
            return (
              <Button size="sm" variant="primary" disabled>
                現在管理者
              </Button>
            );
          }
          return (
            <Button size="sm" variant="primary">
              管理者にする
            </Button>
          );
        },
        size: 120,
      },
      {
        header: "編集",
        Cell: ({ row }) => {
          return (
            <Button size="sm" variant="primary" href={getDynamicRoute(AppRoutes.companyUserEdit, { id, userId: row.original.id })}>
              編集
            </Button>
          );
        },
        size: 120,
      },
    ],
    [],
  );

  const { data } = useCompanyUserList();
  const { table } = useTable({ columns, rows: data ?? [] });

  return (
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="sub16Bold">企業ユーザー</Typography>
        <Button component={Link} href={getDynamicRoute(AppRoutes.companyUserCreate, { id })} size="sm" variant="outline">
          企業ユーザー追加
        </Button>
      </Stack>
      <Table table={table} />
    </Stack>
  );
};
