export const spacing = {
  xxs: "4px",
  xs: "8px",
  sm: "16px",
  md: "24px",
  lg: "32px",
  xl: "40px",
  "2xl": "48px",
} as const;

export const rounded = {
  xs: "4px",
  sm: "8px",
  md: "12px",
  lg: "16px",
  full: "50%",
} as const;

export const elevation = {
  lv1: "0px 2px 6px 0px #00000026",
  lv2: "0px 4px 10px 0px #00000026",
  lv3: "0px 8px 24px 0px #00000026",
  lg: "0px 10px 15px -3px #0000001A, 0px 4px 6px -4px #1018281A",
  sm: "0px 1px 2px 0px #1018280D",
} as const;
