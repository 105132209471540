export const hexToRgba = (hexColor: string, alpha: number) => {
  hexColor = hexColor.replace("#", "");
  // Convert hexadecimal to RGB
  const red = parseInt(hexColor.substring(0, 2), 16);
  const green = parseInt(hexColor.substring(2, 4), 16);
  const blue = parseInt(hexColor.substring(4, 6), 16);

  // Validate alpha value
  alpha = Math.min(1, Math.max(0, alpha));

  // Format and return RGBA string
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};
