import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { TCompanyUserDto } from "@/features/CompanyDetail/type/companyUserDto";
import { affiliation } from "@/features/CompanyUserCreateOrEdit/constants/companyUser";
import { schema } from "@/features/CompanyUserCreateOrEdit/schema/createForm";
import { createCompanyUserApi } from "@/features/CompanyUserCreateOrEdit/services/createCompanyUserApi";
import { updateCompanyUserApi } from "@/features/CompanyUserCreateOrEdit/services/updateCompanyUserApi";
import { TCompanyUserForm } from "@/features/CompanyUserCreateOrEdit/types/companyUserForm";
import { useToast } from "@/hooks/useToast";
import { isApiError } from "@/lib/api";
import { getDynamicRoute } from "@/lib/url";
import { AppRoutes } from "@/routes/config";
import { USER_TYPE } from "@/types/enum";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const initCrateForm: TCompanyUserForm = {
  fullName: "",
  email: "",
  type: "",
  companyDepartment: "",
};

type TCompanyUserFormProps = {
  previousData?: TCompanyUserDto;
};

export const CompanyUserForm: FC<TCompanyUserFormProps> = ({ previousData }) => {
  const toast = useToast();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const methods = useForm<TCompanyUserForm>({
    defaultValues: previousData ?? initCrateForm,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const { control } = methods;
  const { type } = useWatch<TCompanyUserForm>({ control });

  const onSubmit = async (data: TCompanyUserForm) => {
    try {
      if (!id) throw new Error();
      if (previousData?.id) {
        const rs = await updateCompanyUserApi(id, previousData.id, data);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        navigate(getDynamicRoute(AppRoutes.companyDetail, { id }));
      } else {
        const rs = await createCompanyUserApi(id, data);
        methods.reset(initCrateForm);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_USER_LIST] });
    } catch (error) {
      if (isApiError(error)) {
        toast.error(error.message);
      }
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Stack gap={2}>
            <FormField control={control} name="fullName" label="氏名">
              <Input placeholder="プレースホルダー" />
            </FormField>
            <FormField control={control} name="type" label="所属">
              <Select options={affiliation} placeholder="企業ユーザー / irohanaメンバー" />
            </FormField>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap={2}>
            <FormField control={control} name="email" label="メールアドレス">
              <Input placeholder="プレースホルダー" />
            </FormField>
            {type === USER_TYPE.COMPANY_USER && (
              <FormField control={control} name="companyDepartment" label="部署">
                <Input placeholder="プレースホルダー" />
              </FormField>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Box textAlign="center" mt={5}>
        <Button type="submit">メールを送信</Button>
      </Box>
    </Form>
  );
};
