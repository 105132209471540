import { background, button, divider, lightBlue, text } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { FCC } from "@/types/common";
import { ButtonProps, css, styled } from "@mui/material";
import { Button as MuiButton } from "@mui/material";

export type TButtonVariant = "primary" | "secondary" | "outline" | "ghost";
export type TButtonSize = "lg" | "md" | "sm" | "xs";

export type TButtonProps = {
  variant?: TButtonVariant;
  size?: TButtonSize;
} & Omit<ButtonProps, "variant" | "size">;

export const Button: FCC<TButtonProps> = ({ variant = "primary", size = "md", fullWidth, children, ...rest }) => {
  return (
    <StyledButton btnVariant={variant} btnSize={size} sx={{ width: fullWidth ? "100%" : "fit-content" }} {...rest}>
      {children}
    </StyledButton>
  );
};

const options = { shouldForwardProp: (propName: string) => !["btnVariant", "btnSize"].includes(propName) };

const StyledButton = styled(MuiButton, options)<{ btnVariant: TButtonVariant; btnSize: TButtonSize }>`
  ${({ btnVariant, btnSize }) => css`
    line-height: 1;
    text-transform: none;
    ${(() => {
      switch (btnVariant) {
        case "primary":
          return PrimaryButtonCSS;
        case "secondary":
          return SecondaryButtonCSS;
        case "outline":
          return OutlineButtonCSS;
        case "ghost":
          return GhostButtonCSS;
        default:
          return PrimaryButtonCSS;
      }
    })()}
    ${(() => {
      switch (btnSize) {
        case "lg":
          return LgButtonCSS;
        case "md":
          return MdButtonCSS;
        case "sm":
          return SmButtonCSS;
        case "xs":
          return XsButtonCSS;
        default:
          return MdButtonCSS;
      }
    })()}
  `}
`;

const PrimaryButtonCSS = css`
  background: ${button.primary};
  border: 2px solid ${button.primary};
  color: ${text.white};
  :hover {
    background: ${lightBlue[99]};
    color: ${button.primary};
  }
  :focus {
    background: ${button.hover_02};
    color: ${button.primary};
  }
  :active {
    background: ${button.pressed};
    color: ${text.white};
    border: 2px solid ${button.pressed};
  }
  :disabled {
    background: ${button.disable};
    border: 2px solid ${button.disable};
    color: ${text.white};
  }
`;

const SecondaryButtonCSS = css`
  background: ${background.white};
  border: 2px solid ${button.primary};
  color: ${button.primary};
  :hover {
    background: ${lightBlue[99]};
  }
  :focus {
    background: ${button.hover_02};
  }
  :active {
    background: ${button.pressed};
    border: 2px solid ${button.pressed};
    color: ${text.white};
  }
  :disabled {
    background: ${button.disable};
    border: 2px solid ${button.disable};
    color: ${text.white};
  }
`;

const OutlineButtonCSS = css`
  background: ${background.white};
  color: ${button.primary};
  border: 2px solid ${button.primary};
  :hover {
    background: ${button.hover_02};
  }
  :focus {
    background: ${button.hover_02};
  }
  :active {
    background: ${button.hover_01};
    color: ${text.white};
    border: 2px solid ${button.pressed};
  }
  :disabled {
    background: ${background.white};
    border: 2px solid ${button.disable};
    color: ${text.tertiary};
  }
`;

const GhostButtonCSS = css`
  color: ${text.primary};
  :hover {
    background: ${divider.low};
  }
  :focus {
    background: ${divider.middle};
  }
  :active {
    background: ${divider.high};
  }
  :disabled {
    background: ${button.disable};
    color: ${text.white};
  }
`;

const LgButtonCSS = css`
  height: 48px;
  padding: 0 30px;
  border-radius: ${rounded.sm};
  font-size: 20px;
`;

const MdButtonCSS = css`
  height: 40px;
  padding: 0 22px;
  border-radius: ${rounded.sm};
  font-size: 16px;
`;

const SmButtonCSS = css`
  height: 32px;
  padding: 0 22px;
  border-radius: ${rounded.lg};
  font-size: 12px;
  font-weight: bold;
`;

const XsButtonCSS = css`
  height: 25px;
  padding: 0 6px;
  border-radius: ${rounded.lg};
  font-size: 12px;
  font-weight: bold;
`;
