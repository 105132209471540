import { Button } from "@/components/elements/Button";
import { Pagination } from "@/components/elements/Pagination";
import { useCompanyList } from "@/features/Company/services/fetchCompanyListApi";
import { AppRoutes } from "@/routes/config";
import { Stack } from "@mui/material";
import React, { FC } from "react";

type TCompanyListTopProps = {};

export const CompanyListTop: FC<TCompanyListTopProps> = () => {
  const { data } = useCompanyList();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Pagination totalPage={data?.pagination.totalPage ?? 1} />
      <Button size="sm" variant="outline" href={AppRoutes.companyCreate}>
        新規作成
      </Button>
    </Stack>
  );
};
