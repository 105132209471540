import { TCompany } from "@/entities/company.entity";
import { TCompanyUserForm } from "@/features/CompanyUserCreateOrEdit/types/companyUserForm";
import { clientApi } from "@/lib/api";
import { TApiRes } from "@/types/api";

export const createCompanyUserApi = async (companyId: string, payload: TCompanyUserForm): Promise<TApiRes<TCompany>> => {
  try {
    const { data } = await clientApi.post<TApiRes<TCompany>>(`/companies/${companyId}/users`, payload);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
