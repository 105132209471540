import { MRT_RowData, MRT_TableOptions, useMaterialReactTable } from "material-react-table";

export type TUseTableProps<T extends MRT_RowData> = {
  rows: T[];
  disableRow?: (row: T) => boolean;
  hightLightRow?: (row: T) => boolean;
} & Omit<MRT_TableOptions<T>, "data">;

export const useTable = <T extends MRT_RowData>({ rows, columns, disableRow, hightLightRow, ...rest }: TUseTableProps<T>) => {
  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableStickyHeader: true,
    enableColumnActions: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableSorting: false,
    muiTableBodyCellProps: ({ row: { original } }) => {
      const classNames = [];

      // add class disabled for row match condition
      const isDisabled = disableRow?.(original);
      if (isDisabled) classNames.push("disabled");

      // add class highlight for row match condition
      const isHighlighted = hightLightRow?.(original);
      if (isHighlighted) classNames.push("highlight");

      if (!classNames.length) return {};

      return { className: classNames.join(" ") };
    },
    ...rest,
  });

  return { table };
};
