import { TCompany } from "@/entities/company.entity";
import { TCompanyForm } from "@/features/CompanyCreateOrUpdate/types/companyForm";
import { clientApi } from "@/lib/api";
import { TApiRes } from "@/types/api";

export const updateCompanyApi = async (companyId: number, payload: TCompanyForm): Promise<TApiRes<TCompany>> => {
  try {
    const { data } = await clientApi.put<TApiRes<TCompany>>(`/companies/${companyId}`, payload);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
