import { background, divider, icon, state, text } from "@/theme/colors";
import { typography } from "@/theme/typography";
import { elevation } from "@/theme/variables";
import { FC } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled, { css } from "styled-components";

export type TToastProps = {};

export const Toast: FC<TToastProps> = () => {
  return <ToastContainerWrap newestOnTop icon={<></>} />;
};

const fontBody = css({
  ".Toastify__toast-body": { ...typography.sub16Semi, lineHeight: "21px", padding: 16, margin: 0 },
});

const ToastContainerWrap = styled(ToastContainer)`
  &.Toastify__toast-container {
    width: 496px;
    & > .Toastify__toast {
      min-height: 68px;
      box-shadow: ${elevation.lg};
      padding: 0;
      padding-right: 16px;
      min-height: 53px;
      ${fontBody};
      & .Toastify__toast-icon {
        width: 0;
        margin-inline-end: 0;
      }
      & .Toastify__close-button {
        margin-top: 16px;
        margin-bottom: 16px;
        background-color: ${background.white};
        width: 36px;
        height: 36px;
        border: 1px solid ${divider.middle};
        border-radius: 6px;
        box-shadow: ${elevation.sm};
        color: ${icon.muted};
        &:hover {
          opacity: 0.8;
        }
      }
      & .Toastify__close-button--light {
        opacity: 1;
        color: ${icon.muted};
      }
    }

    & > .Toastify__toast--success {
      border: 1px solid ${state.success_1};
      background-color: ${state.success_2};
      color: ${state.success_1};
    }

    & > .Toastify__toast--error {
      border: 1px solid ${text.error};
      background-color: ${background.error};
      color: ${text.error};
    }

    & > .Toastify__toast--default {
      border: 1px solid ${divider.middle};
    }
  }
`;
