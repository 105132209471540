import { Button } from "@/components/elements/Button";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { QuoteScope } from "@/components/elements/QuoteScope";
import { schema } from "@/features/Login/schema/loginForm";
import { loginApi } from "@/features/Login/services/loginApi";
import { TLoginRequest } from "@/features/Login/types/TLoginRequest";
import { isApiError } from "@/lib/api";
import { AppRoutes } from "@/routes/config";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const LoginForm = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { isValid },
    resetField,
  } = useForm<TLoginRequest>({
    mode: "all",
    resolver: yupResolver(schema),
  });
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = async (data: TLoginRequest) => {
    try {
      await loginApi(data);
      navigate(AppRoutes.company);
    } catch (error) {
      if (isApiError(error)) {
        setErrorMsg(error.message);
        resetField("password");
      }
    }
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} gap={3} width={400}>
      {errorMsg && <QuoteScope variant="danger">{errorMsg}</QuoteScope>}
      <FormField control={control} name="email" label="メールアドレスまたはユーザーID">
        <Input placeholder="irohana@iro.co.jp" size="lg" />
      </FormField>
      <FormField control={control} name="password" label="パスワード">
        <Input type="password" size="lg" />
      </FormField>
      <Button type="submit" variant="primary" fullWidth disabled={!isValid}>
        ログイン
      </Button>
    </Stack>
  );
};
