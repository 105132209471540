import { InnerBox } from "@/components/elements/InnerBox";
import { Grid, Stack, Typography } from "@mui/material";

export const CompanySummary = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Stack gap={2}>
          <InnerBox>
            <Typography variant="sub16Bold"> 企業情報</Typography>
          </InnerBox>
          <InnerBox>
            <Typography variant="sub16Bold">備考欄（フリーテキスト）</Typography>
            <Typography>行政書士に依頼</Typography>
            <Typography>顧客カルテURL</Typography>
          </InnerBox>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <InnerBox height="100%" justifyContent="center">
          <Stack direction="row" height="100%" gap={4} justifyContent="center" alignItems="center">
            <Stack gap={1}>
              <Typography variant="sub16Semi">当月1日時点での課金人数</Typography>
              <Typography variant="head32Bold" textAlign="center">
                10人
              </Typography>
            </Stack>
            <Stack gap={1}>
              <Typography variant="sub16Semi">現時点の課金人数</Typography>
              <Typography variant="head32Bold" textAlign="center">
                10人
              </Typography>
            </Stack>
          </Stack>
        </InnerBox>
      </Grid>
    </Grid>
  );
};
