import { QUERY_KEYS } from "@/constants/queryKeys";
import { globalApi } from "@/lib/api";
import { TApiRes } from "@/types/api";
import { TOption } from "@/types/common";
import { useQuery } from "@tanstack/react-query";

type TEnumName = "EUserType" | "EAdminRole" | "ECompanyContractStatus";

export const fetchEnumApi = async (enumName: TEnumName): Promise<TOption[]> => {
  try {
    const { data } = await globalApi.get<TApiRes<TOption[]>>(`/enums/${enumName}/options`);
    return data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useEnum = (enumName: TEnumName) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.FETCH_ENUM, enumName],
    queryFn: () => fetchEnumApi(enumName),
  });
  return { data: data ?? [], ...rest };
};
