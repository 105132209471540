import { AppDrawer } from "@/components/layout/elements/AppDrawer";
import { AppHeader } from "@/components/layout/elements/AppHeader";
import { withAuth } from "@/hoc/withAuth";
import { useVisible } from "@/hooks/useVisible";
import { background } from "@/theme/colors";
import { spacing } from "@/theme/variables";
import { Box, Stack, styled } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";

export const AdminLayout: FC = () => {
  const showDrawer = useVisible();
  return (
    <>
      <Stack minHeight="100vh" direction="row">
        <AppDrawer isOpenDrawer={showDrawer.visible} toggleDrawer={showDrawer.toggle} />
        <Stack flex={1}>
          <AppHeader />
          <ChildrenWrapper onClick={showDrawer.close}>
            <Outlet />
          </ChildrenWrapper>
        </Stack>
      </Stack>
    </>
  );
};

export const AdminLayoutWithAuth = withAuth(AdminLayout);

const ChildrenWrapper = styled(Box)`
  flex: 1;
  padding: ${spacing.md};
  width: 100%;
  background-color: ${background.primary};
`;
