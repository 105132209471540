import { Key } from "react";

/**
 * ex: /company/:id => /company/1
 * @param {string} pathname - pathname is define in AppRoutes
 * @param {number} params - Object to replace params in dynamic route
 * @returns {string} - DynamicRoute.
 *
 */
export const getDynamicRoute = (pathname: string, params: Record<string, Key | undefined>): string => {
  return Object.entries(params).reduce((cur, [key, value]) => (value ? cur.replace(`:${key}`, value.toString()) : cur), pathname);
};

/**
 * ex: /company/:id => /company/1
 * @param {string} url - origin url
 * @param {number} params - Object to create search param
 * @returns {string} - url with search param.
 *
 */
export const createUrlWithParams = <D = object>(url: string, data: D): string => {
  if (!data) {
    return url;
  }

  const dataAry = Object.entries<number | string | boolean | string[] | number[]>(data).filter(([_, value]) => {
    return !(value === null || value === undefined);
  });
  if (!dataAry.length) {
    return url;
  }

  const params = dataAry.map(([key, value]) => {
    // For arrays use the same key and create a key=value string for each value
    if (Array.isArray(value)) {
      return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join("&");
    }

    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  });

  return [url, params.join("&")].join("?");
};

/**
 * @param {string} url - url to verify
 * @returns {boolean} - true isUrl
 */
export const isUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 * @returns {string} - return tenant domain
 */

export const getTenantDomain = (): string => {
  const url = window.location.hostname;
  return url.replace("admin.", "");
};

/**
 * @param {string} url - url to verify
 * @returns {boolean} - true isInternalTenant
 */
export const isInternalTenantLink = (url: string): boolean => {
  try {
    const linkUrl = new URL(url);
    return linkUrl.hostname.includes(getTenantDomain());
  } catch (error) {
    return false;
  }
};
