import { background, button } from "@/theme/colors";
import { rounded, spacing } from "@/theme/variables";
import { FCC } from "@/types/common";
import { Box, styled } from "@mui/material";
import { useMemo } from "react";

export type TQuoteVariant = "danger";

export type TQuoteScopeProps = {
  variant: TQuoteVariant;
};

export const QuoteScope: FCC<TQuoteScopeProps> = ({ variant, children }) => {
  const quoteColor = useMemo(() => {
    switch (variant) {
      case "danger":
        return {
          bar: button.secondary,
          background: background.orange,
        };
      default:
        return {
          bar: button.pressed,
          background: background.white,
        };
    }
  }, [variant]);

  return (
    <QuoteScopeWrap bgcolor={quoteColor.background}>
      <Bar bgcolor={quoteColor.bar} />
      {children}
    </QuoteScopeWrap>
  );
};

const QuoteScopeWrap = styled(Box)`
  border-radius: ${rounded.xs};
  position: relative;
  padding: ${spacing.sm} ${spacing.md};
`;

const Bar = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 100%;
  border-radius: ${rounded.md};
`;
