import { TLoginResponse } from "@/features/Login/types/TLoginResponse";
import { clientApi } from "@/lib/api";
import { setToken } from "@/lib/token";
import { TApiRes } from "@/types/api";
import Cookies from "js-cookie";

export const refreshTokenApi = async (): Promise<void> => {
  try {
    const email = Cookies.get("email");
    const {
      data: { data },
    } = await clientApi.post<TApiRes<TLoginResponse>>("/v1/refresh-token", {
      refreshToken: Cookies.get("refreshToken"),
      email,
    });
    setToken(data.accessToken, data.refreshToken);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};
