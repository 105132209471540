import { QUERY_KEYS } from "@/constants/queryKeys";
import { TCompanyUserDto } from "@/features/CompanyDetail/type/companyUserDto";
import { clientApi } from "@/lib/api";
import { TApiRes } from "@/types/api";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const fetchCompanyUserApi = async (companyId: string, userId: string): Promise<TCompanyUserDto> => {
  try {
    const { data } = await clientApi.get<TApiRes<TCompanyUserDto>>(`/companies/${companyId}/users/${userId}`);
    return data.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const useCompanyUser = () => {
  const { id, userId } = useParams();

  return useQuery({
    queryKey: [QUERY_KEYS.COMPANY.FETCH_USER_DETAIL],
    queryFn: () => (id && userId ? fetchCompanyUserApi(id, userId) : undefined),
    enabled: Boolean(id && userId),
  });
};
