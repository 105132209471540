export const AppRoutes = {
  // Login
  login: "/",
  logout: "/logout",

  // Company
  company: "/company",
  companyCreate: "/company/create",
  companyDetail: "/company/:id/detail",
  companyEdit: "/company/:id/edit",
  companyUserCreate: "/company/:id/user/create",
  companyUserEdit: "/company/:id/user/:userId/edit",

  // Admin user
  adminUser: "/admin-user",
  adminUserCreate: "/admin-user/create",
  adminUserDetail: "/admin-user/:id/edit",
  adminUserEdit: "/admin-user/:id",

  // Notification
  notification: "/notification",
  notificationCreate: "/notification/create",
  notificationDetail: "/notification/:id",

  // Convert
  convert: "/convert",
  convertHistory: "/convert/history",
  convertDetail: "/convert/:id",
};

export const CompanyRoutes = [
  AppRoutes.company,
  AppRoutes.companyCreate,
  AppRoutes.companyDetail,
  AppRoutes.companyEdit,
  AppRoutes.companyUserCreate,
  AppRoutes.companyUserEdit,
];

export const AdminUserRoutes = [AppRoutes.adminUser, AppRoutes.adminUserCreate, AppRoutes.adminUserDetail, AppRoutes.adminUserEdit];

export const NotificationRoutes = [AppRoutes.notification, AppRoutes.notificationCreate, AppRoutes.notificationDetail];

export const ConvertRoutes = [AppRoutes.convert, AppRoutes.convertHistory, AppRoutes.convertDetail];

export const LoginRoutes = [AppRoutes.login];

export const AdminRoutes = [CompanyRoutes, AdminUserRoutes, NotificationRoutes, ConvertRoutes].flat();
