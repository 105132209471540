import { IconExpandMore } from "@/assets/icons";
import { background } from "@/theme/colors";
import { spacing } from "@/theme/variables";
import { Box, Stack, styled, Typography } from "@mui/material";
import { FC } from "react";

type TAppHeaderProps = {};

export const AppHeader: FC<TAppHeaderProps> = () => {
  return (
    <BoxWrapper>
      <Stack flexDirection="row" alignItems="center" gap={spacing.xs}>
        <Typography variant="body14" fontWeight={500}>
          ユーザーアカウント
        </Typography>
        <IconExpandMore cursor="pointer" fontSize="18" />
      </Stack>
    </BoxWrapper>
  );
};

const BoxWrapper = styled(Box)`
  padding: ${spacing.sm} ${spacing.xl};
  border-bottom: 1px solid #7f7f7f;
  background-color: ${background.primary};
  display: flex;
  justify-content: end;
`;
