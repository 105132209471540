import { Button } from "@/components/elements/Button";
import { Form } from "@/components/elements/Form";
import { FormField } from "@/components/elements/FormField";
import { Input } from "@/components/elements/Input";
import { Select } from "@/components/elements/Select";
import { Textarea } from "@/components/elements/Textarea";
import { API_SUCCESS_MESSAGE } from "@/constants/common";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { TCompany } from "@/entities/company.entity";
import { specificIndustryOptions } from "@/features/CompanyCreateOrUpdate/constants/company";
import { schema } from "@/features/CompanyCreateOrUpdate/schema/createForm";
import { createCompanyApi } from "@/features/CompanyCreateOrUpdate/services/createCompanyApi";
import { updateCompanyApi } from "@/features/CompanyCreateOrUpdate/services/updateCompanyApi";
import { TCompanyForm } from "@/features/CompanyCreateOrUpdate/types/companyForm";
import { useToast } from "@/hooks/useToast";
import { isApiError } from "@/lib/api";
import { getDynamicRoute } from "@/lib/url";
import { AppRoutes } from "@/routes/config";
import { useEnum } from "@/services/fetchEnumApi";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const initCrateForm: TCompanyForm = {
  companyDescription: "",
  companyName: "",
  contractStatus: "",
  note: "",
  specificIndustry: "",
};

type TCompanyFormProps = {
  previousData?: TCompany;
};

export const CompanyForm: FC<TCompanyFormProps> = ({ previousData }) => {
  const { data: companyContractStatus } = useEnum("ECompanyContractStatus");
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const methods = useForm<TCompanyForm>({
    defaultValues: previousData ?? initCrateForm,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const { control } = methods;

  const onSubmit = async (data: TCompanyForm) => {
    try {
      if (previousData?.id) {
        const rs = await updateCompanyApi(previousData.id, data);
        navigate(getDynamicRoute(AppRoutes.companyDetail, { id: previousData.id }));
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_DETAIL] });
      } else {
        const rs = await createCompanyApi(data);
        methods.reset(initCrateForm);
        toast.success(rs.message ?? API_SUCCESS_MESSAGE);
      }
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.COMPANY.FETCH_LIST] });
    } catch (error) {
      if (isApiError(error)) {
        toast.error(error.message);
      }
    }
  };

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Stack gap={2}>
            <FormField control={control} name="companyName" label="企業名">
              <Input placeholder="プレースホルダー" />
            </FormField>
            <FormField control={control} name="contractStatus" label="ステータス">
              <Select options={companyContractStatus} placeholder="契約中" />
            </FormField>
            <FormField control={control} name="companyDescription" label="企業情報">
              <Textarea placeholder="プレースホルダー" />
            </FormField>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack gap={2}>
            <FormField control={control} name="specificIndustry" label="特定産業分野">
              <Select options={specificIndustryOptions} placeholder="介護" />
            </FormField>
            <FormField control={control} name="note" label="備考">
              <Textarea placeholder="プレースホルダー" />
            </FormField>
          </Stack>
        </Grid>
      </Grid>
      <Box textAlign="center" mt={5}>
        <Button type="submit">追加</Button>
      </Box>
    </Form>
  );
};
