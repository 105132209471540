import { TAdminUser } from "@/entities/adminUser.entity";
import { TCreateAdminUserReqDto } from "@/features/AdminUserCreateOrUpdate/types/createAdminUser.dto";
import { clientApi } from "@/lib/api";
import { TApiRes } from "@/types/api";

export const createAdminUserApi = async (payload: TCreateAdminUserReqDto): Promise<TApiRes<TAdminUser>> => {
  try {
    const { data } = await clientApi.post<TApiRes<TAdminUser>>("/users", payload);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
