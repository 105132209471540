import { ReactNode, useCallback } from "react";
import { Bounce, ToastOptions, toast } from "react-toastify";

const defaultConfig: TToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  rtl: false,
  draggable: true,
  theme: "colored",
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  transition: Bounce,
};

export type TToastType = "default" | "success" | "error";
export type TToastOptions = Omit<ToastOptions, "type">;

export const useToast = () => {
  const show = useCallback((type: TToastType, content: ReactNode, options?: TToastOptions) => {
    toast(content, { type, ...defaultConfig, ...options });
  }, []);

  const success = useCallback((message: string, options?: TToastOptions) => {
    show("success", message, options);
  }, []);

  const error = useCallback((message: string, options?: TToastOptions) => {
    show("error", message, options);
  }, []);

  const info = useCallback((message: string, options?: TToastOptions) => {
    show("default", message, options);
  }, []);

  return {
    show,
    info,
    error,
    success,
  };
};
