import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { AdminUserTable } from "@/features/AdminUser/components/AdminUserTable";
import { TopPage } from "@/components/elements/TopPage";
import { AdminUserListTop } from "@/features/AdminUser/components/AdminUserListTop";

type TAdminUserPageProps = {};

export const AdminUserPage: FC<TAdminUserPageProps> = () => {
  return (
    <Box>
      <TopPage title="irohanaメンバー一覧" enablePageBack={false} />
      <Stack gap={3}>
        <AdminUserListTop />
        <AdminUserTable />
      </Stack>
    </Box>
  );
};
