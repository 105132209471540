import { LoginForm } from "@/features/Login/components/LoginForm";
import { Stack, Typography } from "@mui/material";
import { FC } from "react";

type TLoginPageProps = {};

export const LoginPage: FC<TLoginPageProps> = () => {
  return (
    <Stack gap={6} alignItems="center">
      <Typography variant="head32Bold">irohana</Typography>
      <LoginForm />
    </Stack>
  );
};
