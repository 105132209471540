export const QUERY_KEYS = {
  GLOBAL: {
    FETCH_ENUM: "GLOBAL_FETCH_ENUM",
  },
  COMPANY: {
    FETCH_LIST: "COMPANY_FETCH_LIST",
    FETCH_DETAIL: "COMPANY_FETCH_DETAIL",
    FETCH_USER_LIST: "COMPANY_FETCH_USER_LIST",
    FETCH_USER_DETAIL: "COMPANY_FETCH_USER_DETAIL",
  },
  ADMIN_USER: {
    FETCH_LIST: "ADMIN_USER_FETCH_LIST",
  },
} as const;
