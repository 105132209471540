import { Clickable } from "@/components/elements/Clickable/Clickable";
import { useVisible } from "@/hooks/useVisible";
import { FCC } from "@/types/common";
import { Popover as MuiPopover, PopoverProps } from "@mui/material";
import { ReactNode, useRef } from "react";

type TPopoverProps = {
  anchor: ReactNode;
} & Omit<PopoverProps, "open" | "anchorEl" | "onClose">;

export const Popover: FCC<TPopoverProps> = ({ children, anchor, ...rest }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { visible, close, open } = useVisible(false);

  return (
    <>
      <Clickable onClick={open} ref={ref}>
        {anchor}
      </Clickable>
      <MuiPopover
        open={visible}
        anchorEl={ref.current}
        onClose={close}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...rest}
      >
        {children}
      </MuiPopover>
    </>
  );
};
