import { valueOf } from "@/types/common";

export const USER_TYPE = {
  ADMIN: "admin",
  ADMIN_MEMBER: "admin_member",
  COMPANY_USER: "company_user",
};

export type TUserType = valueOf<typeof USER_TYPE>;

export const ADMIN_ROLE = {
  MANAGER: "manager",
  SALE: "company_sale",
  CUSTOM_SERVICE: "custom_service",
  AUDITOR: "auditor",
};

export type TAdminRole = valueOf<typeof ADMIN_ROLE>;
