import { AdminUserRoutes, AppRoutes, CompanyRoutes, ConvertRoutes, NotificationRoutes } from "@/routes/config";

export const menus = [
  {
    text: "受け入れ企業管理",
    path: AppRoutes.company,
    relatedRoutes: CompanyRoutes,
  },
  {
    text: "irohana担当者",
    path: AppRoutes.adminUser,
    relatedRoutes: AdminUserRoutes,
  },
  {
    text: "提出形式の変換依頼",
    path: AppRoutes.convert,
    relatedRoutes: ConvertRoutes,
  },
  {
    text: "お知らせ配信",
    path: AppRoutes.notification,
    relatedRoutes: NotificationRoutes,
  },
] as const;
