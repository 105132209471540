import { TLoginRequest } from "@/features/Login/types/TLoginRequest";
import { TLoginResponse } from "@/features/Login/types/TLoginResponse";
import { clientApi } from "@/lib/api";
import { setToken } from "@/lib/token";
import { TApiRes } from "@/types/api";

import Cookies from "js-cookie";

export const loginApi = async (payload: TLoginRequest): Promise<TLoginResponse> => {
  try {
    const {
      data: { data },
    } = await clientApi.post<TApiRes<TLoginResponse>>("/login", payload);

    setToken(data.accessToken, data.refreshToken);
    Cookies.set("email", data.email);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
